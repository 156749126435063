<template>
  <div id="award-edit">
    <v-card>
      <v-card-title>
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <router-link to="/zone/individual-award/list">
          <v-btn
            color="blue"
            min-width="92"
            rounded
            medium
            class="font-size-h6 px-3 py-3 mr-3 white--text"
          >
            Back
          </v-btn>
        </router-link>
        <v-btn
          @click.prevent="refreshPageData"
          color="#a4c639"
          min-width="92"
          rounded
          medium
          class="btn font-size-h6 px-3 py-3 mr-3 white--text"
          >Refresh
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-content-center mt-2 mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <h3 class="text-dark" align="center">Award Details</h3>
        <v-container v-if="row != ''">
          <v-row>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Status</h6>
              <h6 class="text-dark">
                <v-chip
                  :color="row.AwardStatusColor"
                  draggable
                  class="font-size-h5 white--text px-4 py-3"
                >
                  {{ row.AwardStatusTxt }}
                </v-chip>
                <!-- {{ row.AwardStatusTxt }} -->
              </h6>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Register Number</h6>
              <h6 class="text-dark">
                {{ row.AwardRegisterNumber }}
              </h6>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Title</h6>
              <h6 class="text-dark">
                {{ row.AwardTypeTitleTxt }}
              </h6>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="getTotalZoneConIndividualAwardsDetails"
          >
            <h3 class="text-dark" align="center">Member Details</h3>
            <v-row wrap class="px-3 py-0">
              <v-col cols="12" sm="12" md="6" lg="6">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Select Member
                  <small class="text-dark-50"
                    >(Only Current Year Dues paid member)</small
                  >
                </h4>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                v-if="Member.membership_id > 0"
              >
                <div>
                  <member-details
                    cardTitle="Award Nominee Member"
                    :Member="Member"
                    class="mt-0 mb-8"
                  >
                  </member-details>
                </div>
              </v-col>
            </v-row>
            <h3 class="text-dark" align="center">Judging Criteria</h3>
            <v-row wrap class="mb-5 p-5">
              <v-col v-if="row == ''">
                <h5 class="text-center text-dark">Nil.</h5>
              </v-col>
              <v-col
                v-else
                cols="12"
                sm="12"
                lg="12"
                md="12"
                v-for="(item, index) in row.AwardAnswersArray"
                :key="index"
              >
                <template v-if="item.CriteriaTitleFlag">
                  <h3 class="criteria-title">
                    {{ item.CriteriaTitle }}
                    <small> (score - {{ item.CriteriaScore }}) </small>
                  </h3>
                  <p></p>
                </template>
                <label :for="item.AnswerId" class="criteria-question-text">
                  <h6 class="text-dark">
                    <span class="text-danger">* </span>
                    {{ item.QuestionsText }}
                  </h6>
                </label>
                <v-textarea
                  :id="item.AnswerId"
                  v-model="item.AnswerText"
                  value=""
                  :maxlength="item.QuestionsCharsCount"
                  :counter="item.QuestionsCharsCount"
                  :rules="AnswerRules"
                  outlined
                  solo
                >
                </v-textarea>
                <h6 class="text-dark mt-0">
                  Add / Delete Attachment (max -
                  {{ item.QuestionsAttachmentMax }})
                  <v-btn
                    icon
                    color="blue"
                    v-if="
                      item.QuestionsAttachmentMax > item.EventDetails.length
                    "
                    @click="addNewRow(item)"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </h6>

                <template v-if="item.EventDetails.length > 0">
                  <v-row class="ml-0">
                    <v-col
                      v-for="(data, index) in item.EventDetails"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="3"
                      xl="3"
                      :key="index"
                      v-if="data.EventId != ''"
                    >
                      <v-card>
                        <v-card-text>
                          <h6 class="text-right">
                            <v-btn
                              v-if="data.EventId != ''"
                              icon
                              color="red"
                              @click="deleteRow(item.SNo, index)"
                            >
                              <v-icon>mdi-delete-circle-outline</v-icon>
                            </v-btn>
                          </h6>
                          <h6 align="center">
                            <viewer rebuild :images="data.EventImagesArray">
                              <img
                                style="padding: 5px"
                                width="70"
                                height="70"
                                :src="src"
                                :key="src"
                                v-for="src in data.EventImagesArray"
                              />
                            </viewer>
                          </h6>
                          <h6>
                            Event Date -
                            <span class="text-dark">{{ data.EventDate }}</span>
                          </h6>
                          <!-- <h6>
                              Area-
                              <span class="text-dark">{{
                                data.AnswerPortfolioName
                              }}</span>
                            </h6> -->
                          <h6>
                            Event Name -
                            <span class="text-dark">{{ data.EventName }}</span>
                          </h6>
                        </v-card-text>
                      </v-card>
                      <!-- <v-simple-table>
                        <template v-if="data.AnswerEventId != ''">
                          <thead>
                            <tr>
                              <th class="text-center">
                                <h6>Event Image</h6>
                              </th>
                              <th class="text-center">
                                <h6>Event Date</h6>
                              </th>
                              <th class="text-center">
                                <h6>Area</h6>
                              </th>
                              <th class="text-center">
                                <h6>Even Name</h6>
                              </th>
                              <th class="text-center">
                                <h6>Actions</h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-center">
                            <tr>
                              <td>
                                <viewer rebuild :images="data.AnswerEventPhoto">
                                  <img
                                    style="padding: 5px"
                                    width="70"
                                    height="70"
                                    :src="src"
                                    :key="src"
                                    v-for="src in data.AnswerEventPhoto"
                                  />
                                </viewer>
                              </td>
                              <td>{{ data.AnswerEventDate }}</td>
                              <td>{{ data.AnswerPortfolioName }}</td>
                              <td>{{ data.AnswerEventName }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table> -->
                    </v-col>
                  </v-row>
                </template>
                <br /><br />
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" cols="12" md="12">
                <!-- <v-btn
                  @click="resetForm"
                  elevation="30"
                  rounded
                  medium
                  color="brown"
                  class="font-size-h6 px-6 mr-3 white--text"
                >
                  Reset
                </v-btn> -->
                <v-btn
                  v-if="row != ''"
                  @click.prevent="getTotalZoneConIndividualAwardsDetails(4)"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="#1db954"
                  class="font-size-h6 px-6 mr-3 white--text"
                >
                  Save
                </v-btn>
              </v-col>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  v-if="row != ''"
                  :disabled="!valid1"
                  @click.prevent="getTotalZoneConIndividualAwardsDetails(3)"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="blue"
                  class="font-size-h6 px-6 white--text"
                >
                  Final Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="70%">
      <answer-attachment-edit
        pageTitle="Add Project Details"
        :recordData="selectedRecord"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="hideAddRecordPrompt($event)"
        v-if="addRecordPrompt"
      ></answer-attachment-edit>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

import AnswerAttachmentEdit from "@/view/pages/erp/jci-award/zonecon/common/AnswerAttachmentEdit.vue";
import MemberDetails from "@/view/pages/erp/jci-award/zonecon/common/MemberDetails.vue";

export default {
  mixins: [common],
  components: { AnswerAttachmentEdit, MemberDetails },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      ResultFlag: false,
      CardLoadingFlag: false,
      UploadFlag: false,
      addButtonFlag: true,

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      Member: {},

      Status: "",

      ZoneconIndividualAwardStartDate: "",
      ZoneconIndividualAwardEndDate: "",
      TotalZoneconIndividualAward: 0,
      TotalApplied: 0,
      TotalPartiallySaved: 0,

      StartDateFlag: false,
      EndDateFlag: false,

      CurrentDateTime: "",
      CurrentDate: "",
      CurrentTime: "",
      CurrentAmPm: "",

      rows: {},

      AwardId: "",
      AwardTypeId: "",

      row: {},
      // CriteriaAnswers: [],
      Project: [],

      selectedRecord: "",
      Id: "",
      showTextBox: false,

      addRecordPrompt: false,

      ImageUploadFlag: false,

      AnswerRules: [(v) => !!v || "Answer is required"],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        var zone_name = this.$session.get("ZoneName");
        zone_name = zone_name == (null || undefined) ? 0 : zone_name;

        this.PageTitle = tr.PageTitle + " - " + zone_name;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    row: function () {
      console.log("watch row called");
      this.getMemberIdOptions();
    },
    MemberIdOptions: function () {
      console.log("watch MemberIdOptions called");
      this.MemberIdOptionsLoading = false;
      this.MemberId = this.row.MemberId;
    },
    MemberId: function () {
      console.log("MemberId watch called");
      this.Member = {};
      this.getSelectedMemberDetails();
    },
    rows: function () {
      console.log("watch rows called");
      this.getCurrentDateTime();
    },
    CurrentDateTime: function () {
      console.log("watch CurrentDateTime called");
      this.getDeadlineDetails();
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "zone_individual_award",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      var id = this.$route.query.id;
      console.log({ id });
      this.AwardId = id;

      this.getTableRecords();
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 7,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getSelectedMemberDetails() {
      console.log("getSelectedMemberDetails called");
      this.Member = {};
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.Member = member;
      }
    },
    resetForm() {
      console.log("resetForm called");
      var rows = this.CriteriaAnswers;

      rows.map((row, index) => {
        row.AnswerText = "";
        row.AllProject = {};
      });

      this.getTableRecords();
    },
    addNewRow(tr) {
      console.log("addNewRow called");
      console.log({ tr });
      this.selectedRecord = tr;
      this.addRecordPrompt = true;
    },
    deleteRow(id, index) {
      console.log("deleteRow called");
      console.log({ id });
      console.log({ index });
      if (index > -1) {
        var row = this.row.AwardAnswersArray;

        row.forEach((data, k) => {
          console.log({ k });
          if (id == k) {
            data.EventDetails.splice(index, 1);
          }
        });

        console.log("row = ", this.row);
      }
    },
    hideAddRecordPrompt(tr) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ tr });

      if (tr.EventId != "") {
        // this.Id = tr.Index;
        console.log("tr.index = ", tr.Index);

        var row = this.row.AwardAnswersArray;

        row.forEach((data, index) => {
          console.log("SNo = ", data.SNo);

          if (tr.Index == data.SNo) {
            console.log("Limit not Crossed");

            var n1 = data.QuestionsAttachmentMax;
            var n2 = data.EventDetails.length;
            console.log({ n1 }, { n2 });

            if (n1 > n2) {
              console.log("Limit not Crossed");

              data.EventDetails.push({
                EventId: tr.EventId,
                EventName: tr.EventName,
                EventDate: tr.EventDate,
                EventImagesArray: tr.EventPhoto,
              });
            } else {
              console.log("Maximum Limit Crossed");
              var Message = "Attachment Maximum Count reached its limit.";
              this.sweetAlert("error", Message, false);
            }
          }
        });

        console.log("row = ", this.row);
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var AwardId = this.AwardId;
      console.log({ AwardId });

      if (AwardId > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award/show";
        var upload = {
          UserInterface: 1,
          Award: AwardId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.RecordFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.RecordFlag = flag;

            if (flag == 1) {
              console.log("records=" + JSON.stringify(records));

              thisIns.row = records;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Award details not found.";
        console.log("error=" + message);
      }
    },
    getTotalZoneConIndividualAwardsDetails(status) {
      console.log("getTotalZoneConIndividualAwardsDetails called");

      var validate1 = true;
      console.log({ validate1 });

      console.log({ status });
      this.Status = status;

      if (validate1) {
        this.CardLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award-type/total-awards";

        var AwardCategory = 2;
        var AwardGroup = 1;

        if (this.RoleId == 2) {
          // For Admin
          var upload = {
            UserInterface: 2,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
            Zone: this.ZoneCode,
            Lom: this.LomCode,
          };
        } else {
          // For Others
          var upload = {
            UserInterface: 2,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
          };
        }

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.CardLoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              console.log("records = " + JSON.stringify(records));
              thisIns.rows = records;
              thisIns.ZoneconIndividualAwardStartDate = records.StartDateTxt;
              thisIns.ZoneconIndividualAwardEndDate = records.EndDateTxt;
              thisIns.TotalZoneconIndividualAward = records.TotalAward;
              thisIns.TotalApplied = records.TotalApplied;
              thisIns.TotalPartiallySaved = records.TotalPartiallySaved;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.CardLoadingFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        console.log("error=" + message);
      }
    },
    getCurrentDateTime() {
      console.log("getCurrentDateTime called");

      // Get new date
      var dateObj = new Date();

      var temp_date = String(dateObj.getDate()).padStart(2, "0");
      var month = String(dateObj.getMonth() + 1).padStart(2, "0");
      var year = dateObj.getFullYear();

      // Get Current Date
      var current_date = year + "-" + month + "-" + temp_date;

      // var temp_hour = String(dateObj.getHours()).padStart(2, "0") % 12;
      // var hour = temp_hour ? temp_hour : 12;
      var hour = String(dateObj.getHours()).padStart(2, "0");
      var minute = String(dateObj.getMinutes()).padStart(2, "0");
      var second = String(dateObj.getSeconds()).padStart(2, "0");

      // Get Current Time
      var current_time = hour + ":" + minute + ":" + second;

      // Get Current AM/PM
      // var current_am_pm = dateObj.getHours() >= 12 ? "pm" : "am";

      console.log({ current_date }, { current_time });

      this.CurrentDate = current_date;
      this.CurrentTime = current_time;
      // this.CurrentAmPm = current_am_pm;

      var current_date_time = current_date + " " + current_time;

      this.CurrentDateTime = current_date_time;
    },
    getDeadlineDetails() {
      console.log("getDeadlineDetails called");

      // this.CurrentDateTime = "2023-07-01 23:00:00";
      var current_date_time = Date.parse(this.CurrentDateTime);
      console.log({ current_date_time });

      var tr = this.rows;

      // Np-Recognition Start Date
      var temp_start_date_time = tr.StartDate1;
      // temp_start_date_time = "2023-07-01 00:00:01";
      var start_date_time = Date.parse(temp_start_date_time);
      console.log({ temp_start_date_time }, { start_date_time });

      if (start_date_time <= current_date_time) {
        console.log("Opened");
        this.StartDateFlag = true;
      } else {
        console.log("Closed");
      }

      // Np-Recognition End Date

      var temp_end_date_time = tr.EndDate1;
      var end_date_time = Date.parse(temp_end_date_time);
      console.log({ temp_end_date_time }, { end_date_time });

      if (end_date_time > current_date_time) {
        console.log("Opened");
        this.EndDateFlag = true;
      } else {
        console.log("Closed");
      }

      console.log("StartDateFlag = ", this.StartDateFlag);
      console.log("EndDateFlag = ", this.EndDateFlag);
      // this.StartDateFlag = false;
      if (this.StartDateFlag && this.EndDateFlag && this.Status != "") {
        this.confirmAlert(this.Status);
      } else {
        this.deadlineAlert();
      }
    },
    deadlineAlert() {
      console.log("deadlineAlert called");
      var htmlTxt = "";
      htmlTxt +=
        "<h3> Zonecon Lom Awards Bidding ended at <b> " +
        this.ZoneconIndividualAwardEndDate +
        " </b> </h3>";
      // htmlTxt += "<br/> Start <b> " + this.ZoneconIndividualAwardEndDate + " </b>";

      Swal.fire({
        title: "Sorry !",
        text: "",
        icon: "warning",
        html: htmlTxt,
      });
    },
    confirmAlert(status) {
      console.log("confirmAlert called");

      var validate1 = false;
      validate1 = status == 4 ? true : this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      this.MemberId =
        this.MemberId == null || this.MemberId == "" ? "" : this.MemberId;

      console.log("MemberId =", this.MemberId);

      var PhotoFlag = this.Member.photo_flag;
      console.log({ PhotoFlag });

      var DocumentFlag = this.Member.document_flag;
      console.log({ DocumentFlag });

      if (this.MemberId != "" && validate1 && PhotoFlag && DocumentFlag) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", false);
          }
        });
      } else {
        var message = "";
        if ((this.MemberId = "" || this.MemberId == null) && !validate1) {
          message = "Kindly select member and fill all the Questions";
        }
        if (this.MemberId == "" || this.MemberId == null) {
          message = "Kindly select Member";
        }
        if (!validate1) {
          message = "Kindly fill all the Questions";
        }
        if (!PhotoFlag) {
          message = "Member Profile not Updated.";
        }
        if (!DocumentFlag) {
          message = "Age document not Updated.";
        }
        console.log({ message });
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(status) {
      console.log("submitForm is called");
      this.SubmitFlag = true;

      console.log({ status });

      var upload = {
        UserInterface: 1,
        ActiveStatus: status,
        Member: this.MemberId,
        AwardGroup: 1,
        AwardCategory: 2,
        ZoneCon: true,
        Award: this.AwardId,
        CriteriaAnswers: this.row,
      };
      console.log("upload=" + JSON.stringify(upload));

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/award/update";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);

            thisIns.$router.push("/zone/individual-award/list");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss"></style>
