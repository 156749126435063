<template>
  <div id="member-details">
    <v-card>
      <v-container>
        <h4 class="text-center text-dark-50 mb-2">
          {{ cardTitle }}
          <span v-if="Member.photo_flag == false" class="ml-9 text-danger">
            <v-icon dark color="red" small> fas fa-exclamation-circle </v-icon>
            Profile not Updated
          </span>
        </h4>
        <h4>
          <span
            v-if="Member.document_flag == false"
            class="ml-9 text-danger text-align=right"
          >
            <v-icon dark color="red" small> fas fa-exclamation-circle </v-icon>
            Age document not Updated
          </span>
          <span v-else class="ml-9  text-align=right" style="color:#008000">
            <v-icon dark color="#008000" small> fas fa-check-circle </v-icon>
            Age document Updated
          </span>
        </h4>

        <v-row wrap>
          <v-col cols="12" sm="12" lg="4" md="3" align="center">
            <h6 class="blue--text">Member Photo</h6>
            <v-img
              :src="Member.photo"
              :alt="Member.member_name"
              max-width="120"
              max-height="120"
              v-if="Member.photo != ''"
            >
            </v-img>
          </v-col>
          <v-col cols="12" sm="12" lg="8" md="9">
            <v-row>
              <v-col cols="12" sm="12" lg="6" md="4">
                <h6 class="blue--text">MembershipId</h6>
                <h6 class="text-dark">{{ Member.membership_id }}</h6>
              </v-col>
              <v-col cols="12" sm="12" lg="6" md="4">
                <h6 class="blue--text">Name</h6>
                <h6 class="text-dark">{{ Member.member_name }}</h6>
              </v-col>
              <v-col cols="12" sm="12" lg="6" md="4">
                <h6 class="blue--text">Email Id</h6>
                <h6 class="text-dark">{{ Member.email_id }}</h6>
              </v-col>
              <v-col cols="12" sm="12" lg="6" md="4">
                <h6 class="blue--text">Mobile No</h6>
                <h6 class="text-dark">{{ Member.mobile_no }}</h6>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    cardTitle: {
      required: true,
      type: String,
    },
    Member: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      alert: {},

      valid: true,

      SubmitFlag: false,
      RecordFlag: false,
      ResultFlag: false,
      LoadingFlag: false,
      ComingSoonFlag: false,

      row: {},

      RoleId: 0,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      console.log("Member = ", this.Member);
    },
  },
  beforeMount() {
    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    console.log({ RoleId });
    this.RoleId = RoleId;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#award-bidding-dashboard {
  // #menu-list {
  //   height: 100px;
  // }
}
</style>
